import { Button, Dialog, Heading, ListBox, ListBoxItem, Modal, ModalOverlay, Text } from 'react-aria-components';
import type { AffinityLevel } from './types';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../icon';
import { AffinityLooseIcon, AffinityMediumIcon, AffinityNoCacheIcon, AffinityStrongIcon } from './icons';

const AffinityLevels = [
  { label: 'No cache', icon: <AffinityNoCacheIcon />, value: 'none', description: 'Semantic caching is disabled' },
  { label: 'Low', icon: <AffinityLooseIcon />, value: 'loose', description: 'Lower cache hit ratio' },
  {
    label: 'Medium (Recommended)',
    icon: <AffinityMediumIcon />,
    value: 'medium',
    description: 'Medium cache hit ratio',
  },
  { label: 'Strong', icon: <AffinityStrongIcon />, value: 'strong', description: 'Higher cache hit ratio' },
];

type AffinityLevelModalProps = {
  affinityLevel: AffinityLevel;
  onSelect: (level: AffinityLevel) => void;
};

export function AffinityLevelModal({ affinityLevel, onSelect }: AffinityLevelModalProps) {
  return (
    <ModalOverlay
      isDismissable
      aria-label="modal"
      className={({ isEntering, isExiting }) =>
        twMerge(
          'fixed inset-0 z-10 flex min-h-full items-center justify-center overflow-y-auto bg-[#00000080] p-4 text-center',
          isEntering ? 'duration-300 ease-out animate-in fade-in' : '',
          isExiting ? 'duration-200 ease-in animate-out fade-out' : '',
        )
      }
    >
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={({ isEntering, isExiting }) =>
          twMerge(
            'w-full max-w-md overflow-hidden rounded-[4px] bg-white text-left align-middle shadow-xl',
            isEntering ? 'duration-300 ease-out animate-in zoom-in-95' : '',
            isExiting ? 'duration-200 ease-in animate-out zoom-out-95' : '',
          )
        }
      >
        <Dialog role="alertdialog" className="relative m-0 outline-none">
          {({ close }) => (
            <div className="flex h-full flex-col">
              <div className="flex items-center justify-between px-6 pb-2 pt-4">
                <Heading slot="title" className="my-0 text-[16px] font-[500] leading-6 tracking-[-0.25px] text-black">
                  Change similarity threshold
                </Heading>

                <Button className="p-2 text-slate-500 hover:text-slate-600" onPress={close}>
                  <Icon icon="xmark" className="h-4 w-4 cursor-pointer text-[#00000066]" />
                </Button>
              </div>
              <div className="flex-1 overflow-y-auto p-6 pt-3">
                <div className="text-slate-500">
                  <div className="flex flex-col">
                    <p className="m-0 text-sm font-normal leading-[18px] tracking-[-0.25px] text-black/85">
                      Determine the similarity threshold for prompts that use different words but have the same meaning:
                    </p>
                  </div>
                  <ListBox
                    items={AffinityLevels}
                    className="mt-3 flex flex-col gap-3"
                    selectionMode="single"
                    selectedKeys={[affinityLevel]}
                    onSelectionChange={(keys) => {
                      const selectedKey = Array.from(keys)[0] as AffinityLevel;
                      onSelect(selectedKey);
                      close();
                    }}
                  >
                    {({ label, icon, value, description }) => (
                      <ListBoxItem
                        key={value}
                        id={value}
                        textValue={label}
                        aria-label={label}
                        className="group flex cursor-pointer select-none flex-col items-start gap-2 rounded border border-black/15 px-2 py-2 text-gray-900 outline-none hover:bg-gray-100 data-[selected]:rounded data-[selected]:bg-[#F9F7FF] data-[selected]:hover:bg-[#F9F7FF]"
                      >
                        {({ isSelected }) => (
                          <div className="flex w-full flex-col gap-1">
                            <div className="flex w-full items-center justify-between gap-2">
                              <div className="flex items-center gap-2">
                                {icon}
                                <Text
                                  slot="label"
                                  className="group-selected:font-medium flex flex-1 items-center gap-2 truncate text-sm font-medium capitalize text-black/85 "
                                >
                                  {label}
                                </Text>
                              </div>
                              {isSelected && <Icon icon="check" className="h-4 w-4 text-[#4000BF]" />}
                            </div>
                            <Text slot="description" className="text-[13px] font-medium text-black/50">
                              {description}
                            </Text>
                          </div>
                        )}
                      </ListBoxItem>
                    )}
                  </ListBox>
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
