import { type ReactNode } from 'react';
import type { BundledLanguage, BundledTheme } from 'shiki';

export type LLM = 'Open AI' | 'Anthropic' | 'Mistral' | 'Cohere';
export type LLMNames = 'openai' | 'anthropic' | 'mistral' | 'cohere';
export type CacheUnit = 'seconds' | 'minutes' | 'days';
export type AffinityLevel = 'none' | 'loose' | 'medium' | 'strong';
export type Guardrails =
  | 'biasAndFairnessChecks'
  | 'explainability'
  | 'privacyProtections'
  | 'contentModeration'
  | 'ethicalAiFrameworks'
  | 'securityProtections'
  | 'regulatoryCompliance'
  | 'shortResponses'
  | 'technologyConcealment';

export type AIRunner = {
  serviceId?: string;
  pluginId?: string;
  semanticPluginId?: string;
  routeId?: string;
  proxyUrl: string;
  affinityLevel: AffinityLevel;
  provider: string;
  cacheTtl: number;
  cacheTtlUnit: CacheUnit;
  vectorTtl: number;
  vectorTtlUnit: CacheUnit;
  cacheControl: boolean;
  authentication: boolean;
  totalRequests: number;
  totalPromptTokens: number;
  totalCacheHits: number;
  totalCacheLatency: number;
  guardrailsPluginId?: string;
  biasAndFairnessChecks: boolean;
  explainability: boolean;
  privacyProtections: boolean;
  contentModeration: boolean;
  ethicalAiFrameworks: boolean;
  securityProtections: boolean;
  regulatoryCompliance: boolean;
  shortResponses: boolean;
  technologyConcealment: boolean;
  created: string;
  modified: string;
};

export type LLMType = {
  label: LLM;
  value: LLMNames;
  icon: ReactNode;
};

export const CacheUnits = [
  { label: 'Seconds', value: 'seconds' },
  { label: 'Minutes', value: 'minutes' },
  { label: 'Days', value: 'days' },
];

export const theme: BundledTheme = 'github-dark-default';
export const langs: BundledLanguage[] = ['java', 'powershell', 'javascript', 'go', 'ruby', 'python'];

export type SortBy = 'last_created' | 'last_updated';
export type SortOrder = 'asc' | 'desc';

export const defaultSettings = {
  provider: 'openai' as LLMNames,
  affinityLevel: 'none' as AffinityLevel,
  cacheTtl: 60,
  cacheTtlUnit: 'seconds' as CacheUnit,
  vectorTtl: 60,
  vectorTtlUnit: 'seconds' as CacheUnit,
  cacheControl: true,
  authentication: false,
  proxyUrl: '',
  totalRequests: 0,
  totalPromptTokens: 0,
  totalCacheHits: 0,
  totalCacheLatency: 0,
  biasAndFairnessChecks: false,
  explainability: false,
  privacyProtections: false,
  contentModeration: false,
  ethicalAiFrameworks: false,
  securityProtections: false,
  regulatoryCompliance: false,
  shortResponses: false,
  technologyConcealment: false,
  created: new Date().toLocaleString(),
  modified: new Date().toLocaleString(),
};
